.ct-chart-line {
    overflow: visible;
    margin-bottom: 30px;
    .ct-grid {
        stroke: theme('colors.light-gray');
        stroke-width: 3px;
        stroke-dasharray: none;
    }

    .ct-point {
        cursor: pointer;
        stroke-width: 11px;
        stroke: currentColor;
    }

    .ct-label.ct-horizontal {
        margin-top: 30px;
        margin-left: -25px;
        letter-spacing: 0px;
        color: theme('colors.gray');
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        transform: rotate(50deg);
    }
    .ct-label.ct-vertical {
        color: theme('colors.gray');
        font-size: 14px;
        font-weight: 600;
        font-family: 'Segoe UI';
        margin-top: 5px;
    }
    .ct-grids {
        .ct-horizontal {
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
    .ct-line {
        color: currentColor;
        stroke: currentColor;
    }

    .ct-point__hexagon {
        stroke-width: 3px;
    }
}

.chart-tooltip {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 20px;
    transition: all 0.1s ease-in;
    z-index: 9;
    .chart-tooltip__arrow {
        position: absolute;
        left: -10px;
        top: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid theme('colors.black');
    }
    .chart-tooltip__content {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    img {
        position: absolute;
        top: -1px;
        left: -27px;
        width: 15px;
    }
}

.chart-color-1,
.ct-series-a {
    fill: theme('colors.secondary');
    color: theme('colors.secondary');
}

.chart-color-2,
.ct-series-b {
    fill: theme('colors.primary');
    color: theme('colors.primary');
}

.chart-color-3,
.ct-series-c {
    fill: theme('colors.link');
    color: theme('colors.link');
}

.chart-color-4,
.ct-series-d {
    fill: theme('colors.desc');
    color: theme('colors.desc');
}
