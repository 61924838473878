@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 200;
    src: url('./segoeui-semilight.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 200;
    src: url('./segoeui-semilight-italic.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 300;
    src: url('./segoeui-light.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 300;
    src: url('./segoeui-light-italic.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    src: url('./segoeui-regular.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    src: url('./segoeui-semibold.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 600;
    src: url('./segoeui-semibold-italic.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 700;
    src: url('./segoeui-bold.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 700;
    src: url('./segoeui-bold-italic.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 900;
    src: url('./segoeui-black.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    font-style: italic;
    font-weight: 900;
    src: url('./segoeui-black-italic.ttf');
}
