@font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 300;
    src: url('./FrankRuhlLibre-Light.ttf');
}

@font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 400;
    src: url('./FrankRuhlLibre-Regular.ttf');
}

@font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 500;
    src: url('./FrankRuhlLibre-Medium.ttf');
}

@font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    src: url('./FrankRuhlLibre-Bold.ttf');
}

@font-face {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 900;
    src: url('./FrankRuhlLibre-Black.ttf');
}
