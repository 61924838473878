.ct-chart-bar {
    overflow: visible;
    margin-bottom: 25px;
    .ct-grid {
        stroke: theme('colors.light-gray');
        stroke-width: 3px;
        stroke-dasharray: none;
    }
    .ct-grids {
        .ct-horizontal {
            display: none;
            &:first-child {
                display: block;
            }
        }
    }
    .ct-label.ct-vertical {
        color: theme('colors.gray');
        font-size: 14px;
        font-weight: 600;
        font-family: 'Segoe UI';
        margin-top: 5px;
    }
    .ct-label.ct-horizontal {
        margin-top: 20px;
        letter-spacing: 0px;
        color: theme('colors.gray');
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        transform: rotate(50deg);
    }
    .ct-bar {
        color: currentColor;
        stroke: currentColor;
    }
}

.chart-color-1,
.ct-series-a,
.ct-series:first-child {
    fill: theme('colors.secondary');
    color: theme('colors.secondary');
}

.chart-color-2,
.ct-series-b,
.ct-series:nth-child(2) {
    fill: theme('colors.primary');
    color: theme('colors.primary');
}

.chart-color-3,
.ct-series-c,
.ct-series:nth-child(3) {
    fill: theme('colors.link');
    color: theme('colors.link');
}

.chart-color-4,
.ct-series-d,
.ct-series:nth-child(4) {
    fill: theme('colors.desc');
    color: theme('colors.desc');
}
