@import './fonts';
@import 'chartist/dist/scss/chartist.scss';
@import 'react-toastify/dist/ReactToastify.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'components';
